import React, { Fragment } from 'react';
import tw from 'twin.macro';
import { PAYMENT_TERM } from '../../constants';
import { PayTermType } from '../../types';

interface PaymentTermInputProps {
  currentPaymentTerm: PayTermType;
  handleClickPaymentTerm: (newValue: PayTermType) => void;
}

const PaymentTermSliderWrapper = tw.div`
flex justify-between items-center
`;

const PaymentTermInput = ({
  currentPaymentTerm,
  handleClickPaymentTerm,
}: PaymentTermInputProps) => {
  return (
    <PaymentTermSliderWrapper>
      {PAYMENT_TERM.map((term, index) => {
        const buttonCSS =
          Number(term) <= currentPaymentTerm
            ? 'text-accent_blue_2 hover:text-[#1890ff]'
            : 'text-accent_grey_2';
        const lineCSS =
          Number(term) < currentPaymentTerm
            ? 'bg-accent_blue_2'
            : 'bg-accent_grey_2';
        const numberCss =
          index !== PAYMENT_TERM.length - 1 ? 'left-0' : 'right-0';

        return (
          <Fragment key={term}>
            <button
              className="relative"
              onClick={() => handleClickPaymentTerm(term)}
            >
              <span
                className={`fa-regular fa-circle ${buttonCSS} text-[22px]`}
              />
              <span className={'absolute top-6 ' + numberCss}>{term}</span>
            </button>

            {index !== PAYMENT_TERM.length - 1 && (
              // Slide payment terms with cursor and snap to nearest circle.
              <>
                <button
                  onClick={() => handleClickPaymentTerm(term)}
                  className={`grow h-1 rounded-[1px_0px_0px_1px] ${lineCSS}`}
                />
                <button
                  onClick={() => {
                    if (!!PAYMENT_TERM[index + 1]) {
                      handleClickPaymentTerm(PAYMENT_TERM[index + 1]);
                    }
                  }}
                  className={`grow h-1 rounded-[0px_1px_1px_0px] ${lineCSS}`}
                />
              </>
            )}
          </Fragment>
        );
      })}
    </PaymentTermSliderWrapper>
  );
};

export default PaymentTermInput;

import React from 'react';
import translation from './translation';
import { withIntl } from 'LanguageProvider/withLanguageProvider';
import DynamicCalculator from './DynamicCalculator';
import LayoutContainer from 'components/LayoutContainer';
import HFSProductPageTemplate from '../productTemplate';
import { graphql, useStaticQuery } from 'gatsby';
import useHFS from 'components/HFS/hooks/useHFS';
import { HFSModals } from 'components/constant';

interface ProductInvoiceFactoringProps {
  pageUrl: string;
}

const ProductInvoiceFactoring = ({ pageUrl }: ProductInvoiceFactoringProps) => {
  const { setActiveModal } = useHFS();
  const data = useStaticQuery(graphql`
    query {
      allStrapiHfsProductPage(filter: { ONLY_FOR_DEV_pageId: { eq: "IF" } }) {
        nodes {
          heroMainBannerDescription
          heroMainBannerHeader

          heroMainBannerCTAButtonLabel
          heroMainBannerBackgroundImage {
            url
          }
          pageLabel
          pageIcon {
            url
          }
          heroFooterBannerCTAHeader
          heroFooterBannerCTADescription
          introduceHeader
          introduceYoutubeEmbedCode
          introduceYoutubeName
          introduceTitle
          introduceDescription
          productOverviewHeader
          productOverviewImage {
            url
            alternativeText
          }
          productOverviewItemDescription {
            name
          }
          productOverviewItemHeader {
            name
          }
          benefitHeader
          benefitCard {
            id
            header
            description
          }
          processHeader
          processImage {
            alternativeText
            url
          }
          processSteps {
            id
            header
            description
          }
          whyHeader
          whyDescription
          whyButtonLabel
          whyItems {
            FontAwesomeIcon
            description
            title
          }
          FAQHeader
          FAQSeeMoreButton
          FAQQuestionAndAnswer {
            description
            header
          }
          hfs_case_study_articles {
            title
            publishedAt
            cover {
              alternativeText
              url
              formats {
                small {
                  url
                }
              }
            }
            author {
              name
            }
            hfs_case_study_topic {
              name
              textHexColorCode
              backgroundHexCodeColor
            }
            description
            slug
            readingTime {
              text
            }
          }
          seo {
            metaDescription
            metaTitle
          }
          updatedAt
        }
      }
    }
  `);

  return (
    <>
      <HFSProductPageTemplate
        data={data}
        pageUrl={pageUrl}
        onClickHeroBtn={() => setActiveModal(HFSModals.IF_APPLICATION)}
        onClickWhyBtn={() => setActiveModal(HFSModals.DOWNLOAD_BROCHURE)}
        extraComponent2={
          <div className="bg-accent_grey_1">
            <LayoutContainer className=" py-[64px] px-[30px]">
              <DynamicCalculator />
            </LayoutContainer>
          </div>
        }
      />
    </>
  );
};

export default withIntl(translation)(ProductInvoiceFactoring);

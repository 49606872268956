import { PayTermType } from './types';

export const MIN_INVOICE_AMOUNT = 0;
export const MAX_INVOICE_AMOUNT = 1000000;
export const SERVICE_FEE: Record<
  PayTermType,
  {
    maxInvoiceAmount: number;
    serviceFeePercent: number;
  }[]
> = {
  30: [
    { maxInvoiceAmount: 100000, serviceFeePercent: 1.2 },
    { maxInvoiceAmount: 200000, serviceFeePercent: 1 },
    { maxInvoiceAmount: 1000000, serviceFeePercent: 0.9 },
  ],
  60: [
    { maxInvoiceAmount: 100000, serviceFeePercent: 2.37 },
    { maxInvoiceAmount: 200000, serviceFeePercent: 1.97 },
    { maxInvoiceAmount: 1000000, serviceFeePercent: 1.78 },
  ],
  90: [
    { maxInvoiceAmount: 100000, serviceFeePercent: 3.55 },
    { maxInvoiceAmount: 200000, serviceFeePercent: 2.96 },
    { maxInvoiceAmount: 1000000, serviceFeePercent: 2.66 },
  ],
  120: [
    { maxInvoiceAmount: 100000, serviceFeePercent: 4.73 },
    { maxInvoiceAmount: 200000, serviceFeePercent: 3.95 },
    { maxInvoiceAmount: 1000000, serviceFeePercent: 3.55 },
  ],
} as const;

export const PAYMENT_TERM = Object.keys(SERVICE_FEE).map(
  Number,
) as PayTermType[];

export const CURRENCY_UNIT = '$';
export const INPUT_CURRENCY_UNIT = 'SGD';

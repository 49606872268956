import React from 'react';
import tw from 'twin.macro';
import { formatNumberToLocaleString } from '../../utils';
import { CURRENCY_UNIT } from '../../constants';

interface EstimatedResultProps {
  isMobile?: boolean;
  value: number;
  currencyUnit?: string;
}

const TextWrapper = tw.div`
flex gap-2 justify-center items-center
`;
const WebTextWrapper = tw(TextWrapper)`
hidden lg:flex
`;
const MobileTextWrapper = tw(TextWrapper)`
lg:hidden mb-6 
`;
const UnitText = tw.div`
text-[20px] font-[500]
`;
const NumberText = tw.div`
text-[24px] font-[600] leading-[36px] lg:text-[32px] lg:font-[700]
`;

const EstimatedResult = ({
  isMobile = false,
  value,
  currencyUnit = CURRENCY_UNIT,
}: EstimatedResultProps) => {
  return (
    <>
      {isMobile ? (
        <MobileTextWrapper>
          <UnitText>{currencyUnit}</UnitText>
          <NumberText>{formatNumberToLocaleString(value)}</NumberText>
        </MobileTextWrapper>
      ) : (
        <WebTextWrapper>
          <UnitText>{currencyUnit}</UnitText>
          <NumberText>{formatNumberToLocaleString(value)}</NumberText>
        </WebTextWrapper>
      )}
    </>
  );
};

export default EstimatedResult;
